button.styled {
    width: 160px;
    height: 43px;
    background: #3F86CC;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    border: none;
    color: #FFFFFF;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

button.styled.active {
  background: gray;
}
