/* You can add global styles to this file, and also import other style files */
@import './styles/button.css';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

html, body { height: 100%; }

body { margin: 0; font-family: 'Noto Sans', sans-serif; }

:root {
  --main-color: #3F86CC;
  --default-font: 'Noto Sans', sans-serif;
}

.mat-tooltip {
    font-size: 14px !important;
}

.mat-header-cell, .mat-cell {
    padding-right: 10px !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
    border-color: black !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle{
    color: red !important;
    background-color: var(--main-color) !important;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color: var(--main-color) !important;
}

.mat-stroked-button {
    color: white;
    background-color: #3F86CC;
}
